// var axios = require('axios/dist/browser/axios.cjs');
axios.defaults.headers.common = {
	"X-Requested-With": "XMLHttpRequest"
};

class Popup {
	constructor(element, parent) {
    this.element = element;
    this.box = element;
		this.btns  = document.querySelectorAll(`.js-popup-toggle[data-popup=${this.element.dataset.popup}]`);
		this.boxes = document.querySelectorAll('.js-popup');
		this.bodyEl = document.querySelector('body');
    this.closeBtn = this.element.querySelector('.js-close-element');
		
		this.open = this.open.bind(this)
		this.close = this.close.bind(this)
		this.toggle = this.toggle.bind(this)
		this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
		this.closeEls = this.box.querySelectorAll('.js-popup-close');
		
		this.clickOutside = this.clickOutside.bind(this);
		this.escPress = this.escPress.bind(this)
    this.init()
  }

	init(){
		if (!this.element.classList.contains('is-init-popup')) {		
				this.addEvents();
				
		}
		this.element.classList.add('is-init-popup');
	}

	addEvents() {	
		this.btns.forEach(btn => { 
			btn.addEventListener('click', this.open)
		})	
		for (const element of this.closeEls) {
			element.addEventListener('click', this.close)
		}
		this.box.addEventListener('click', this.clickOutside)
		document.addEventListener('keyup', this.escPress);		
	}

	closeAll() {
		this.boxes.forEach(box => {			
			box.classList.remove('active');
			this.bodyEl.classList.remove('popup-opened');
		})
	}

	onOpen(cb) {
    if (typeof callBack === 'function') {
      cb()
    }
  } 

  onClose(cb) {
    if (typeof callBack === 'function') {
      cb()
    }
  }

	open() {
		this.closeAll();
		this.bodyEl.classList.add('popup-opened');
		this.box.classList.add('active');

		this.onOpen();
	}

	close() {
		this.bodyEl.classList.remove('popup-opened');
		this.box.classList.remove('active');

		this.onClose()
	}

	toggle() {
		if (this.open()) {
			this.close()
		} else {
			this.open()
		}
	}

	escPress(event) {		
		if (event.keyCode === 27 ) {
			
			this.close()
		} 
	}

	clickOutside(event) {
		const target = event.target
		const id = this.element.dataset.id;
		if(event.target.classList.contains('js-popup-overlay')) {
			this.close()
		}
		if (target.id === id || target.parentElement.id === id) {
			this.close()
		}
	}

	destroy() {
		this.element.removeEventListener('click', this.open)
		for (const element of this.closeEls) {
			element.removeEventListener('click', this.close)
		}
		this.box.removeEventListener('click', this.clickOutside)
		document.removeEventListener('keyup', this.escPress);	
		this.element.classList.remove('is-init-popup');
		this.bodyEl.classList.remove('popup-opened');
	}

}
class ChangeHtml {
	constructor(element, parent) {
    this.element = element;
		this.inputs = this.element.querySelectorAll('.js-check-input');
		this.boxes = this.element.querySelectorAll('.js-check-box');
		this.bodyEl = document.querySelector('body');
		
		this.change = this.change.bind(this);

    this.init()
  }

	init(){
		if (!this.element.classList.contains('is-init-changeHtml')) {		
				this.addEvents();
				this.showCheck();
				this.element.classList.add('is-init-changeHtml');
				
		}
	}

	addEvents() {
		this.inputs.forEach(input => {
			input.addEventListener('change', this.change)
		})
	}

	change(e) {
		let check = e.target;
		const boxCheck = this.element.querySelector(`.js-check-box[data-check="${check.dataset.check}"]`);

		this.hideAll();
		if(boxCheck.classList.contains('hidden')) {
			boxCheck.classList.remove('hidden')
		} 
		
	}

	hideAll() {
		this.boxes.forEach(box => {
			box.classList.add('hidden')
		})
	}

	showCheck() {
    this.inputs.forEach(btn => {
      if(btn.checked) {
        let block = this.element.querySelector(`.js-check-box[data-check="${btn.dataset.check}"]`);
        if(block.classList.contains('hidden')) {
          block.classList.remove('hidden');
        }
      }
    })
  }

	destroy() {
		this.element.classList.remove('is-init-changeHtml');
		this.inputs.forEach(input => {
			input.removeEventListener('change', this.change)
		})
	}
}

class Header {
	constructor(element, parent) {
    this.element = element;
		
		this.elems = this.element.querySelectorAll('.js-hover-btn');
		this.boxes = this.element.querySelectorAll('.js-hover-box');
		this.bodyEl = document.querySelector('body');
		this.parent = this.element.querySelector('.main-header__bottom');
		this.htmlEl = document.querySelector('html');
		
		this.show = this.show.bind(this);
		this.closeAll = this.closeAll.bind(this);

    this.init()
  }

	init(){
		if (!this.element.classList.contains('is-init-header')) {		
				this.checkTouch();
				this.addEvents();
				this.element.classList.add('is-init-header');
				
		} else {
			
		}
	}

	checkTouch() {
		if(this.htmlEl.classList.contains('touch')) {
			this.elems.forEach(el => {
				if(el.classList.contains('active')) {
					this.closeAll();
					this.boxes.forEach(box => {
						if(el.dataset.hover == box.dataset.boxHover) {
							box.classList.add('is-show')
						}
					})
				}
			})
		}
	}

	addEvents() {
		this.elems.forEach(elem => {
			elem.addEventListener('mouseenter', this.show)
			// elem.addEventListener('mouseleave', this.closeAll);
			this.parent.addEventListener('mouseleave', this.closeAll);
		})
	}

	show(e) {
		
		this.closeAll(e);
		this.boxes.forEach(box => {

			if(e.currentTarget.dataset.hover == box.dataset.boxHover) {
				box.classList.add('is-show')
			} else {
				box.classList.remove('is-show')
			}
		})
	}

	closeAll(e) {
			
		this.boxes.forEach((box) => {
			box.classList.remove('is-show')
			
		})
	}
}

class GetDate {
	constructor(element, parent) {
    this.element = element;
		
		this.timeBoxes = this.element.querySelectorAll('.js-getdate-time');
		this.day = null;
		this.dateElem = null;

    this.init()
  }

	init(){
		if (!this.element.classList.contains('is-init-getdate')) {		
				this.dateCheck();
				this.element.classList.add('is-init-getdate');
				
		}
	}

	dateCheck() {
		this.timeBoxes.forEach(elem => {
			this.day = elem.querySelector('.js-getdate-day');
			this.dateElem = elem.querySelector('.js-getdate-date');
			
			if(this.day) {
				this.changeDay(this.day)
			}

			if(this.dateElem) {
				this.changeDate(elem, this.dateElem)
			}
		})
	}

	changeDay(element) {
		const date = new Date();
		let day = date.getDay();

		switch (day) {
			case 0: day = 'Неделя'; break;
			case 1: day = 'Понеделник'; break;
			case 2: day = 'Вторник'; break;
			case 3: day = 'Сряда'; break;
			case 4: day = 'Четвъртък'; break;
			case 5: day = 'Петък'; break;
			case 6: day = 'Събота'; break;
		}

		this.day.innerHTML = day;
	}

	changeDate(time, el) {
		const date = new Date();
		const year = date.getFullYear();
		let month = date.getMonth() + 1;
		let dateDate = date.getDate();
		const shortYear = year.toString().substr(2, 3);
		let hours = date.getHours();
		hours = hours < 10 ? '0' + hours : hours;
		let minutes = date.getMinutes();
		minutes = minutes < 10 ? '0' + minutes : minutes;
		month = month < 10 ? '0' + month : month;
		dateDate = dateDate < 10 ? '0' + dateDate : dateDate;

		time.setAttribute('datetime', `${year}-${month}-${dateDate} ${hours}:${minutes}`)

		this.dateElem.innerHTML = `${dateDate}.${month}.${shortYear} / ${hours}:${minutes}`;

	}
}

class HowTime {
	constructor(element, parent) {
    this.element = element;		
		this.boxes = this.element.querySelectorAll('.js-how-time-elem');

    this.init()
  }

	init(){
		if (!this.element.classList.contains('is-init-howtime')) {
			this.boxes.forEach( elem => {
				this.checkDate(elem);
			})

			this.element.classList.add('is-init-howtime');
				
		} 
	}

	checkDate(element) {
		const date = new Date();
		const timeElement = element.dataset.time.split(':');
		let yearEl = element.dataset.date.split('-')[0];
		let monthEl = element.dataset.date.split('-')[1];
		let dayEl = element.dataset.date.split('-')[2];
		let newDate = new Date(yearEl, monthEl - 1, dayEl,timeElement[0], timeElement[1] );
		let resMinutes = Math.round((date.getTime() - newDate.getTime())/ (1000 * 60));
		let resHours = Math.round((date.getTime() - newDate.getTime())/ (1000 * 60 * 60));
		let resDays = Math.round((date.getTime() - newDate.getTime())/ (1000 * 60 * 60 * 24))
		
		if(resMinutes <= 59) {
			element.innerHTML = `Преди ${resMinutes} мин.`
			resMinutes < 1 ? element.innerHTML = `Преди 1 мин.` : element.innerHTML = `Преди ${resMinutes} мин.`
		} else if (resHours < 24) {
			resHours < 2 ? element.innerHTML = `Преди ${resHours} час` : element.innerHTML = `Преди ${resHours} часа`;
		} else if (resHours >= 24) {
			resDays < 2 ? element.innerHTML = `Преди ${resDays} ден` : element.innerHTML = `Преди ${resDays} дни`;
		}

	}

}

if(document.querySelector('.js-show-scroll-button')) {
	scrollPageGlobal()
}

function scrollPageGlobal() {
	let allElems;
	let topSpacing;

	if (window.innerWidth > 1200) {
		topSpacing = 250;

		if(document.querySelector('.js-sticky-header')) {
			topSpacing = 270 + Math.round(document.querySelector('.js-sticky-header .sticky-header__head').getBoundingClientRect().height);
		}
	}  else if (window.innerWidth < 480) {
		topSpacing = Math.round(document.querySelector('.main-header').getBoundingClientRect().height) + 410;
	} else if (window.innerWidth < 768 && window.innerHeight > 480) {
		topSpacing = Math.round(document.querySelector('.main-header').getBoundingClientRect().height) + 320;
	}
	
	if(window.location.hash.length > 0 && document.querySelectorAll('[data-section]').length > 0) {
		allElems = document.querySelectorAll('[data-section]');
		
		allElems.forEach((elem, index) => {
			
			if(index > 0) {
				if(elem.dataset.section == window.location.hash.slice(1)) {
					
					window.scrollTo({
						top: elem.offsetTop - topSpacing,
						behavior: "smooth",
					});
				}
			}
		})

	}
}

class Application {
	constructor() {
    // axios.defaults.headers.common = {
    //   "X-Requested-With": "XMLHttpRequest"
    // };

		this.popup = Popup;
    this.popupInstances = {};
		
		this.changeHtml = ChangeHtml;
    this.changeHtmlInstances = {};
		
		this.header = Header;
    this.headerInstances = {};

		this.getDate = GetDate;
		this.getDateInstances = {};

		this.howTime = HowTime;
		this.howTimeInstances = {};

    this.init();
  }

	init() {
		this.initPopup();
		this.initChangePopup();
		this.initHeader();
		this.initGetDate();
		this.initHowTime();
	}

	//popup
  initPopup() {
    if (document.querySelector('.js-popup')) {
      
      const instances = document.querySelectorAll('.js-popup')
      instances.forEach((el) => {
        if (el.classList.contains('is-init-popup')) {
          return
        } else {
          this.popupInstances[el.dataset.popup] = new Popup(el, {}, this)
        }
      })
    }
  }

  destroyPopup() {
    for (const property in this.popupInstances) {
      this.popupInstances[property].destroy()
    }
  }

	//changehtml
	initChangePopup() {
    if (document.querySelector('.js-check-wrapper')) {
      this.changeHtmlInstances = {}
      const instances = document.querySelectorAll('.js-check-wrapper')
      instances.forEach((el) => {
        if (el.classList.contains('is-init-changeHtml')) {
          return
        } else {
          this.changeHtmlInstances[el.dataset.id] = new ChangeHtml(el, {}, this)
        }
      })
    }
  }

  destroyChangeHtml() {
    for (const property in this.changeHtmlInstances) {
      this.changeHtmlInstances[property].destroy()
    }
  }

	//header
	initHeader() {
    if (document.querySelector('.js-header')) {
      this.headerInstances = {}
      const instances = document.querySelectorAll('.js-header')
      instances.forEach((el) => {
        if (el.classList.contains('is-init-header')) {
          return
        } else {
          this.headerInstances[el.dataset.id] = new Header(el, {}, this)
        }
      })
    }
  }

		//getDate
		initGetDate() {
			if (document.querySelector('.js-getdate')) {
				
				const instances = document.querySelectorAll('.js-getdate')
				instances.forEach((el) => {
					if (el.classList.contains('is-init-getdate')) {
						return
					} else {
						this.getDateInstances[el.dataset.id] = new GetDate(el, {}, this)
					}
				})
			}
		}
	
		destroyGetDate() {
			for (const property in this.getDateInstances) {
				this.getDateInstances[property].destroy()
			}
		}

		//howTime
		initHowTime() {
			if (document.querySelector('.js-how-time')) {
				
				const instances = document.querySelectorAll('.js-how-time')
				instances.forEach((el) => {
					if (el.classList.contains('is-init-howTime')) {
						return
					} else {
						this.howTimeInstances[el.dataset.id] = new HowTime(el, {}, this)
					}
				})
			}
		}
	
		destroyHowTime() {
			for (const property in this.howTimeInstances) {
				this.howTimeInstances[property].destroy()
			}
		}
}

window.StudioX = new Application();



$(document).ready(function () {

	customSelect();
	sliderCounter();
	initSlider();
	sliderButtonsPosition();
	subnav();
	autocomplete();
	stickyHeader();
	playVideoIframe();
	// changeHtml();
	reveralInput();
	unDisable();
	validLanguage();
	loader();
});


var resizeId;
$(window).resize(function () {
	clearTimeout(resizeId);
	resizeId = setTimeout(doneResizing, 100);
});

$(document).on('scroll', function () {
	stickyHeader();
});
var respNavs = {}
$('.js-header-nav-link').on('mouseenter', function () {
	var $this = $(this);
	var $linkData = $this.data('link');
	$('.js-header-respnav').each(function () {
		var $subMenuData = $(this).data('link');
		if ($subMenuData === $linkData) {
			if(!$(this).hasClass('is-opened')){
				$(this).addClass('is-opened');
				respNavs[$linkData] = new ResponsiveNavigation($(this)[0]);
			}
		}
	});
});

// tabs
$('.js-tabs-nav-button').on('click', function (e) {
	e.preventDefault();
	var $this = $(this),
		tabs = $this.parents('.js-tabs'),
		tabsContentItem = tabs.find('.js-tabs-content-item'),
		tabIndex = $this.index();
	if (!$this.hasClass('active')) {
		tabs.find('.js-tabs-nav-button.active').removeClass('active');
		$this.addClass('active');
		tabsContentItem.slideUp('fast');
		tabsContentItem.eq(tabIndex).slideDown('fast');
	}
});

// open/close menu
$(document).on('click', '.js-menu-toggle', function () {
	var buttonType = $(this).data('menu-item');

	if (buttonType) {
		$('.js-menu-item.active').removeClass('active');
		$('.js-menu-item[data-menu-item="' + buttonType + '"]').addClass('active');

		if (buttonType == 'search') {
			$('#menu-search').focus();
		}
	}

	$('body').toggleClass('menu-opened');
});

// $(document).on('click', '.js-popup-toggle', function () {
	
// 	var $this = $(this);
// 	var popupType = $(this).data('popup');
// 	var popup;
// 	$('.js-popup.active').removeClass('active');

// 	if (popupType) {
// 		popup = $('.js-popup[data-popup="' + popupType + '"]'); 
// 		popup.addClass('active');
// 		if (popupType === 'horoscope') {
// 			horoscopePopup($this, popup);
// 		}
// 	}

// 	$('body').toggleClass('popup-opened');




function horoscopePopup(el, popup) {
	var horoscopeTitle = el.data('title');
	var horoscopeIcon = el.data('url');
	var horoscopeText = el.data('text');
	var popupTitle = popup.find('.js-popup-title');
	var popupIcon = popup.find('.js-popup-icon');
	var popupText = popup.find('.js-popup-text');

	popupTitle.text(horoscopeTitle);
	popupIcon.find('use')[0].setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', horoscopeIcon);
	popupText.text(horoscopeText);

}
// profile menu nav button
$(document).on('click', '.js-add-btn', function () {
	$('.js-box').toggleClass('is-open');
});

// menu nav button
$(document).on('click', '.js-menu-nav-toggle', function () {
	var $this = $(this);

	$this.parents('.js-menu-nav').toggleClass('active');
	$this.parents('.js-menu-nav-item').toggleClass('active');
});

// map region
$(document).on('click', '.js-map-region-item', function () {
	window.location.replace($(this).data('htooltip'));
});

// close popups on popup-overlay clicked
$(document).on('click', '.js-popup-overlay', function () {
	closePopups();
});

$(document).on('click', function (e) {
	var el = $('.js-wrap-box');
	if(!el.is(e.target) && el.has(e.target).length === 0 ) {
		$('.js-box').removeClass('is-open');
	}

});

// show and scroll
$(document).on('click', '.js-show-scroll-button', function (e) {
	e.preventDefault();
	
	var $this = $(this),
		sectionToShow = $('*[data-section="' + $this.data('show') + '"]'),
		sectionToScroll = $('*[data-section="' + $this.data('scroll') + '"]');

	if (!sectionToShow.hasClass('active')) {
		sectionToShow.addClass('active');
		sectionToShow.stop(true, true).slideDown();
	}
	scrollToElement(sectionToScroll);

	if($this.hasClass('js-show-scroll-button-hide')) {
		$this.addClass('hidden')
	}
})

$(document).keyup(function (e) {
	if (e.keyCode == 27) {
		closePopups();
	}
});

// tooltips
if ($('html').hasClass('no-touch')) {
	$(document).on('mouseenter', '.js-tooltip', function () {
		var tooltip = $(this),
			tooltipContainer = $('.js-tooltip-container', tooltip);
		if (tooltipContainer.is(':visible')) {
			tooltipContainer.hide();
		}
		else {
			$('.js-tooltip-container').hide();
			tooltipContainer.show();
		}
		var popper = new Popper(tooltip, tooltipContainer, {
			placement: 'bottom',
			modifiers: {
				preventOverflow: {
					boundariesElement: 'viewport'
				}
			}
		});
	}).on('mouseleave', '.js-tooltip', function () {
		$('.js-tooltip-container').hide();
	});
}

function doneResizing() {
	sliderButtonsPosition();
	// grid();
	stickyHeader();
	initSlider();
}

function dateFilter() {
	if($('.js-datepicker-box').length) {
		$('.js-datepicker-box').each(function(boxDatepicket) {
			$this = $(this);

			var elemInputDatepicker = $this.find('.js-date-picker');
			var today = new Date();
			var select_day = elemInputDatepicker.attr('data-defaultdate');
			// var select_day = elemInputDatepicker.attr('value');
			
			var formSubmit = $this.find('.form-submit');
			
			elemInputDatepicker.datepicker({
				regional: 'bg',
				dateFormat: 'yy-mm-dd',
				changeMonth: true,
				changeYear: true,
				showOtherMonths: true,
				selectOtherMonths: true,
				maxDate:today,
				defaultDate: new Date(select_day),
				altField: '#date',
				onSelect: function (dateText, inst)
					{
						// formSubmit.submit();
					
					}
			});

			// elemInputDatepicker.datepicker("option", "defaultDate", select_day)
		})
	}

	$.datepicker.setDefaults($.datepicker.regional["bg"]);
	// $.datepicker.setDefaults({firstDay:1,changeMonth:true,changeYear:true,showOtherMonths:true,selectOtherMonths:true,showAnim:"",duration:0});

	(function(factory){if(typeof define==="function"&&define.amd){define(["../datepicker"],factory);}else{factory(jQuery.datepicker);}}(function(datepicker){datepicker.regional['bg']={closeText:'затвори',prevText:'&#x3C;назад',nextText:'напред&#x3E;',nextBigText:'&#x3E;&#x3E;',currentText:'днес',monthNames:['Януари','Февруари','Март','Април','Май','Юни','Юли','Август','Септември','Октомври','Ноември','Декември'],monthNamesShort:['Яну','Фев','Мар','Апр','Май','Юни','Юли','Авг','Сеп','Окт','Нов','Дек'],dayNames:['Неделя','Понеделник','Вторник','Сряда','Четвъртък','Петък','Събота'],dayNamesShort:['Нед','Пон','Вто','Сря','Чет','Пет','Съб'],dayNamesMin:['Не','По','Вт','Ср','Че','Пе','Съ'],weekHeader:'Wk',dateFormat:'dd.mm.yy',firstDay:1,isRTL:false,showMonthAfterYear:false,yearSuffix:''};datepicker.setDefaults(datepicker.regional['bg']);return datepicker.regional['bg'];}));
}

// dateFilter();


function initSlider() {
	if ($('.js-slider-holder').length) {
		$('.js-slider-holder').each(function () {
			$('.js-slider-holder').find('.no-slider').removeClass('no-slider');
			if (!$(this).find('.slick-initialized').length) {
				var sliderHolder = $(this),
					slider = sliderHolder.find('.js-slider'),
					sliderFor = sliderHolder.find('.js-slider-for'),
					sliderNav = sliderHolder.find('.js-slider-nav'),
					sliderPrevButton = sliderHolder.find('.js-slider-prev-button'),
					sliderNextButton = sliderHolder.find('.js-slider-next-button'),
					sliderDots = sliderHolder.find('.js-slider-dots'),
					sliderSettings = {},
					syncSliderSettingFor = {},
					syncSliderSettingNav = {};

				if ($('.js-slider-settings', sliderHolder).length) {
					sliderSettings = JSON.parse(sliderHolder.find('.js-slider-settings').html());

					if (sliderPrevButton.length) {
						sliderSettings.prevArrow = sliderPrevButton;
					}
					else {
						sliderSettings.prevArrow = false;
					}
					if (sliderNextButton.length) {
						sliderSettings.nextArrow = sliderNextButton;
					}
					else {
						sliderSettings.nextArrow = false;
					}
					if (sliderDots.length) {
						sliderSettings.appendDots = sliderDots;
					}

					sliderSettings.adaptiveHeight = true;

					slider.slick(sliderSettings);
				}

				if ($('.js-sync-slider-settings-for', sliderHolder).length && $('.js-sync-slider-settings-nav', sliderHolder).length) {
					syncSliderSettingFor = JSON.parse(sliderHolder.find('.js-sync-slider-settings-for').html());
					syncSliderSettingFor.asNavFor = sliderNav;
					syncSliderSettingFor.adaptiveHeight = true;
					sliderFor.slick(syncSliderSettingFor);

					syncSliderSettingNav = JSON.parse(sliderHolder.find('.js-sync-slider-settings-nav').html());
					if (sliderPrevButton.length) {
						syncSliderSettingNav.prevArrow = sliderPrevButton;
					}
					else {
						syncSliderSettingNav.prevArrow = false;
					}
					if (sliderNextButton.length) {
						syncSliderSettingNav.nextArrow = sliderNextButton;
					}
					else {
						syncSliderSettingNav.nextArrow = false;
					}
					syncSliderSettingNav.asNavFor = sliderFor;
					sliderNav.slick(syncSliderSettingNav);
				}
			}
		});
	}
}

function sliderButtonsPosition() {
	var highestSliderItem = 0;

	if ($('.js-slider-holder').length) {
		$('.js-slider-holder').each(function () {
			var sliderHolder = $(this),
				sliderHeightMeasure = sliderHolder.find('.js-slider-height-measure');
			if (sliderHeightMeasure.length) {
				sliderHeightMeasure.each(function () {
					var sliderHeightMeasureItem = $(this).outerHeight();

					if (sliderHeightMeasureItem > highestSliderItem) {
						highestSliderItem = sliderHeightMeasureItem;
					}
				});

				var sliderButtons = sliderHolder.find('.js-slider-buttons button');
				if (!sliderButtons.hasClass('positioned')) {
					sliderButtons.addClass('positioned');
				}
				sliderButtons.css('top', highestSliderItem / 2 + 'px');
			}
		});
	}
}

function customSelect() {
	if ($('.js-select').length && $('html').hasClass('no-touch')) {
		$('.js-select').each(function () {
			$(this).select2({
				dropdownParent: $(this).parent()
			});
		});
	}
}

function subnav() {
	if ($('.js-header-subnav').length) {
		var nav = priorityNav.init({
			mainNavWrapper: '.js-header-subnav', // mainnav wrapper selector (must be direct parent from mainNav)
			mainNav: '.js-header-subnav-list', // mainnav selector. (must be inline-block)
			navDropdownClassName: 'main-header__subnav-dropdown', // class used for the dropdown.
			navDropdownToggleClassName: 'nav__dropdown-toggle', // class used for the dropdown toggle.
			navDropdownToggleClassName: 'main-header__subnav-button',
			// navDropdownLabel: '<span class="three-dots"></span>',
			navDropdownBreakpointLabel: '<span class="three-stripes"></span>'
		});
		//priority nav on hover
		if ($('html').hasClass('no-touch')) {
			$(document).on('mouseenter', '.main-nav__dropdown-wrapper', function () {
				$(this).find('.main-header__subnav-button').trigger('click');
			});
			$('.main-nav__dropdown-wrapper').on('mouseleave', function () {
				if ($('.js-header-subnav').hasClass('is-open')) {
					$(this).find('.main-header__subnav-button').trigger('click');
				}
			});
		}
	}
}

function autocomplete() {
	if ($('.js-autocomplete').length) {
		$('.js-autocomplete').each(function () {
			var $this = $(this);

			if ($('.js-autocomplete-options').length) {
				var autocompleteOptions = JSON.parse($('.js-autocomplete-options').html());
			}

			$this.easyAutocomplete(autocompleteOptions);
		});
	}
}

function stickyHeader() {
	if ($('.js-sticky-header').length) {
		var stickyHeader = $('.js-sticky-header'),
			windowHeight = $(window).height(),
			scroll = $(window).scrollTop(),
			scrollBottom = scroll + windowHeight,
			headerHeight = $('.main-header').height(),
			sectionProgress = $('.js-progress');
		if (sectionProgress.length) {
			var sectionProgressHeight = sectionProgress.height(),
				sectionProgressTop = sectionProgress.position().top,
				sectionProgressBottom = sectionProgressTop + sectionProgressHeight,
				percent = 0;
		}

		if (scroll >= headerHeight) {
			if (!stickyHeader.hasClass('active')) {
				stickyHeader.addClass('active');
			}

			if (scroll >= sectionProgressTop && scrollBottom <= sectionProgressBottom || scroll < sectionProgressTop && scrollBottom <= sectionProgressBottom) {
				percent = (((scrollBottom - sectionProgressTop) / sectionProgressHeight) * 100);
			}
			else if (scrollBottom > sectionProgressBottom) {
				percent = 100;
			}
			else if (scrollBottom < sectionProgressTop) {
				percent = 0;
			}

			$('.js-sticky-header-progress').css('width', percent + '%');
		}
		else {
			stickyHeader.removeClass('active');
		}
	}

}

function sliderCounter() {
	if ($('.js-slider-counter').length) {
		$('.js-slider-counter').each(function () {
			var slider = $(this),
				allSlidesLength = slider.find('.js-slider-item').length;

			slider.find('.js-all-slides').text(allSlidesLength);

			$('.js-slider-item', slider).each(function () {
				var sliderItem = $(this),
					currentSlideIndex = sliderItem.index() + 1;

				sliderItem.find('.js-current-slide').text(currentSlideIndex);

			});
		});
	}
}


function closePopups() {
	$('body').removeClass('menu-opened popup-opened');
	$('.popup.active').removeClass('active');
}

function scrollToElement(element) {

	var height = 0;

	if ($('.js-sticky-header').length && $(window).innerWidth() > 768) {
		height = parseInt($('.js-sticky-header').height());
	}
	
	if(element.offset()) {
		$('html, body').animate(
			{
				scrollTop: (element.offset().top - height) - 100
			},
			500
		);
	}
}

function playVideoIframe() {
	if ($('.js-play-video-btn').length) {
		$('.js-play-video-btn').each(function() {
			var buttonPlay = $(this);
			
			buttonPlay.on('click', function(ev) {
				$(".js-video-play")[0].src += "&autoplay=1";
				ev.preventDefault();
				buttonPlay.addClass('hidden')
			
			});
		})
	}
}

function addHiddenCheckBox() {
	if($('.js-check-box').length) {
		$('.js-check-box').each(function() {
			$(this).addClass('hidden');
		})
	}
}

function changeHtml() {
	// addHiddenCheckBox()
	if ($('.js-check-wrapper').length) {
		$('.js-check-wrapper').each(function() {
			var parentblock = $(this);

			if (parentblock.find('.js-check-input').length) {
				var elInput = parentblock.find('.js-check-input');
				elInput.each(function() {
		
					if($(this).attr('checked')) {
						var dataCheck = $(this).data('check');
						
						parentblock.find('.js-check-box').each(function() {									
							if($(this).attr('data-check') == dataCheck) {						
								$(this).removeClass('hidden')
							}
						})
					}
		
					$(this).on('change', function(ev) {
						ev.preventDefault();
						
						var dataCheckChange = $(this).data('check');
						// addHiddenCheckBox();
						
						parentblock.find('.js-check-box').each(function() {									
							if($(this).attr('data-check') == dataCheckChange) {						
								$(this).removeClass('hidden')
							} else {
								$(this).addClass('hidden')
							}
						})
					})
				})
			}
		})
	}
}

function reveralInput() {
	if($('.js-reveal').length) {
		$('.js-reveal').each(function(){

			var parentEl = $(this);
			var btnRev = $(this).find('.js-reveal-btn');
			var inputRev = $(this).find('.js-reveal-input');
		
			btnRev.on('click', function(ev) {
				ev.preventDefault();
				if (parentEl.hasClass('is-open')){
					closeReveal(parentEl,inputRev);
					return;
				}
				openReveal(parentEl,inputRev);
			})
		})
	}		
}

function openReveal(el, inp) {
	el.addClass('is-open');
	inp.attr("type", "text");
}
function closeReveal(el, inp) {
	el.removeClass('is-open');
	inp.attr("type", "password");
}

function unDisable() {
	if($('.js-disabled').length) {
		$('.js-disabled').each(function() {
			var btn = $(this).find('.js-undis-btn');
			var inputDis = $(this).find('.js-dis-input')
			btn.on('click', function(ev) {
				ev.preventDefault();
				inputDis.attr("disabled", false)
			})

		})
	}
}

function validLanguage() {
	var parent = document.querySelectorAll('.js-valid-language');
	if(parent.length) {
		parent.forEach( function(el) {	
			var textBlock = el.querySelector('.js-textblock');
			var errorText = el.querySelector('.site-form__error--language');

			textBlock.addEventListener('input', function(e)  {
				var words = textBlock.value.split(/\s+/);
				words.forEach(function(word) {
					var urlPattern = /^(https?:\/\/[^\s]+)?$/;
					var letterPattern = /^[а-яА-Я0-9!@#\$%\^\&*\]\[\)\(\"\'+=._,:;-]+$/;
					var letterPatternEnglish = /^[a-zA-Z]+$/;
					var quotePattern = /[!![\/a-zA-Z]+!!]/;
					var profileNamePattern = /"[ a-zA-Zа-яА-Я]+/;
					var profileNamePattern2 = /[ a-zA-Zа-яА-Я]+"/;
					
					if(letterPatternEnglish.test(word)) {
						
						errorText.parentNode.classList.add('has-error');
						errorText.classList.remove('hidden');
					} else {
						errorText.classList.add('hidden');
						errorText.parentNode.classList.remove('has-error');
					}

					if(quotePattern.test(word) || profileNamePattern.test(word) || profileNamePattern2.test(word)) {

					} else {
						if (!urlPattern.test(word) && !letterPattern.test(word) ) {
							e.preventDefault();
							textBlock.value = textBlock.value.slice(0, -1);
							
							return
						}
					}
				})
				
				
			});

		})
	}

}

function loader() {
	var loader = document.querySelector('.js-loader');
	document.body.insertAdjacentHTML('beforeend', generateLoaderHtml());
}

function generateLoaderHtml() {
	var html = '<div class="loader js-loader hidden"></div>'
	
	return html;
}

function showLoader() {
	var loader = document.querySelector('.js-loader');
	loader.classList.remove('hidden');
	return ;
}

function hideLoader() {
	var loader = document.querySelector('.js-loader');
	loader.classList.add('hidden');
	return ;
}

"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function ResponsiveNavigation(nav) {
  var _this = this;

  this.nav = nav;
  this.navHolder = this.nav.querySelector('.js-resp-nav__holder');
  this.navList = this.nav.querySelector('.js-resp-nav__list');
  this.navItems = _toConsumableArray(this.nav.querySelectorAll('.js-resp-nav__item'));
  this.dropdown = null;
  this.dropdownButton = null;
  this.dropdownButtonText = this.nav.dataset.buttonText || null;
  this.breakpoint = Number(this.nav.dataset.mobileNavWidth) || 0;
  this.navListWidth = 0;
  this.navHolderWidth = 0;
  this.sum = 0;
  this.init();
  window.addEventListener('resize', function () {
    _this.init();
  });
}

ResponsiveNavigation.prototype.init = function () {
  this.addInitClass();
  this.resetElements();
  this.getWidths();
  this.compareWidths();
};

ResponsiveNavigation.prototype.addInitClass = function () {
  if (!this.navList.classList.contains('is-initialized')) {
    this.navList.classList.add('is-initialized');
  }
};

ResponsiveNavigation.prototype.removeInitClass = function () {
  if (this.navList.classList.contains('is-initialized')) {
    this.navList.classList.remove('is-initialized');
  }
};

ResponsiveNavigation.prototype.resetElements = function () {
  this.navItems.forEach(function (element, index) {
    element.classList.remove('is-hidden');
  });
};

ResponsiveNavigation.prototype.getWidths = function () {
  this.navHolderWidth = this.navHolder.clientWidth;
  this.navListWidth = this.navList.scrollWidth;
};

ResponsiveNavigation.prototype.compareWidths = function () {
  this.removeElements();
  this.getWidths();

  if (this.navListWidth > this.navHolderWidth) {
    this.createElements();
    this.checkSum();
  } else {
    this.hideDropdown();
  }
};

ResponsiveNavigation.prototype.createElements = function () {
  this.createDropdownButton();
  this.createDropdown();
};

ResponsiveNavigation.prototype.createDropdownButton = function () {
  var _this2 = this;

  if (!this.nav.querySelector('.js-resp-nav__button')) {
    this.dropdownButton = document.createElement('button');
    this.dropdownButton.setAttribute('type', 'button');
    this.dropdownButton.classList.add('resp-nav__button');
    this.dropdownButton.classList.add('js-resp-nav__button');

    if (this.dropdownButtonText) {
      this.dropdownButton.innerHTML = this.dropdownButtonText;
    }

    this.nav.appendChild(this.dropdownButton);
    this.dropdownButton.addEventListener('click', function () {
      _this2.toggleDropdown();
    });
  }
};

ResponsiveNavigation.prototype.createDropdown = function () {
  if (!this.nav.querySelector('.js-resp-nav__dropdown')) {
    this.dropdown = document.createElement('ul');
    this.dropdown.classList.add('resp-nav__dropdown');
    this.dropdown.classList.add('js-resp-nav__dropdown');
    this.nav.appendChild(this.dropdown);
  }
};

ResponsiveNavigation.prototype.createDropdownItem = function (href, text, activeClass, title) {
  var dropdownItem = document.createElement('li');
  dropdownItem.classList.add('resp-nav__dropdown-item');
  var dropdownLink = document.createElement('a');
  dropdownLink.classList.add('resp-nav__dropdown-link');
  dropdownLink.setAttribute('href', href);
  dropdownLink.innerHTML = text;

  if (activeClass) {
    dropdownLink.classList.add(activeClass);
  }

  if (title) {
    dropdownLink.setAttribute('title', title);
  }

  dropdownItem.appendChild(dropdownLink);
  this.dropdown.appendChild(dropdownItem);
};

ResponsiveNavigation.prototype.removeElements = function () {
  this.removeDropdownButton();
  this.removeDropdown();
};

ResponsiveNavigation.prototype.removeDropdown = function () {
  if (this.dropdown) {
    var parent = this.dropdown.parentNode;
    var index = Array.prototype.indexOf.call(parent.children, this.dropdown); //Get index of element that has to be removed

    parent.removeChild(parent.children[index]);
    this.dropdown = null;
  }
};

ResponsiveNavigation.prototype.removeDropdownButton = function () {
  if (this.dropdownButton) {
    var parent = this.dropdownButton.parentNode;
    var index = Array.prototype.indexOf.call(parent.children, this.dropdownButton); //Get index of element that has to be removed

    parent.removeChild(parent.children[index]);
    this.dropdownButton = null;
  }
};

ResponsiveNavigation.prototype.hideDropdown = function () {
  if (this.nav.classList.contains('is-open')) {
    this.nav.classList.remove('is-open');
  }
};

ResponsiveNavigation.prototype.showDropdown = function () {
  if (!this.nav.classList.contains('is-open')) {
    this.nav.classList.add('is-open');
  }
};

ResponsiveNavigation.prototype.toggleDropdown = function () {
  this.nav.classList.toggle('is-open');
};

ResponsiveNavigation.prototype.checkSum = function () {
  this.resetElements();
  this.getWidths();
  this.calcSum();
};

ResponsiveNavigation.prototype.calcSum = function () {
  var _this3 = this;

  this.sum = 0;
  this.navItems.forEach(function (element, index) {
    var itemWidth = element.scrollWidth;
    _this3.sum += itemWidth;

    if (window.innerWidth > _this3.breakpoint) {
      if (_this3.sum > _this3.navHolderWidth) {
        _this3.moveItemToDropdown(element);
      }
    } else {
      _this3.moveAllToDropdown();
    }
  });
};

ResponsiveNavigation.prototype.moveItemToDropdown = function (element) {
  element.classList.add('is-hidden');
  var hiddenItemHref = element.querySelector('.js-resp-nav__link').getAttribute('href');
  var hiddenItemTitle = element.querySelector('.js-resp-nav__link').getAttribute('title') || null;
  var hiddenItemText = element.querySelector('.js-resp-nav__link').textContent;
  var activeClass = null;

  if (element.querySelector('.js-resp-nav__link').classList.contains('is-active')) {
    activeClass = 'is-active';
  }

  this.createDropdownItem(hiddenItemHref, hiddenItemText, activeClass, hiddenItemTitle);
};

ResponsiveNavigation.prototype.moveAllToDropdown = function () {
  var _this4 = this;

  this.removeDropdown();
  this.createDropdown();
  this.resetElements();
  this.navItems.forEach(function (element, index) {
    _this4.moveItemToDropdown(element);
  });
};

ResponsiveNavigation.prototype.destroy = function () {
  this.removeInitClass();
  this.resetElements();
  this.removeElements();
};